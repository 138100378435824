/*
 * Copyright © Ergonode Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */
export default () => ({
    drafts: {},
    id: null,
    sku: '',
    type: '',
    completeness: {},
    workflow: {},
    templates: {},
});
