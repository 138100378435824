/*
 * Copyright © Ergonode Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */

import extendRoutesChildren from '@ProductsComments/config/extendRoutesChildren';

export default {
    extendRoutesChildren,
};
