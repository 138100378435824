/*
 * Copyright © Ergonode Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */
export default () => ({
    id: null,
    name: '',
    description: '',
    privileges: [],
});
